import jQuery from 'jquery'
import AOS from 'aos'
import 'aos/dist/aos.css'
import 'slick-carousel/slick/slick'
window.$ = window.jQuery = jQuery

const pvf = ($ => {
  return {
    init() {
      AOS.init({
        duration: 1000
      })
      this.mobileMenu()
      this.twitterSlick()
      this.animateScroll()
      this.getCurrentYear()
    },
    mobileMenu() {
      $('.navbar-burger').on('click', () => {
        $('.navbar-burger').addClass('is-active')
        $('.navbar-mobile').addClass('is-active')
      })

      $('.navbar-close').on('click', () => {
        $('.navbar-burger').removeClass('is-active')
        $('.navbar-mobile').removeClass('is-active')
      })
    },
    twitterSlick() {
      $('.twitter-row').slick({
        slidesToShow: 3,
        // autoplay: true,
        // autoplaySpeed: 4000,
        arrows: false,
        dots: true,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
    },
    animateScroll() {
      let active = 0
      const lastIndex = $('[data-scroll-index]:last').attr('data-scroll-index')

      const navigate = function(ndx) {
        if(ndx < 0 || ndx > lastIndex) return

        const targetTop = $('[data-scroll-index=' + ndx + ']').offset().top - 100
        $('html,body').animate({
            scrollTop: targetTop,
            easing: 'linear',
        }, 300)
      }

      const doScroll = function (e) {
        const target = $(e.target).closest("[data-scroll-nav]").attr('data-scroll-nav') ||
        $(e.target).closest("[data-scroll-goto]").attr('data-scroll-goto')
        navigate(parseInt(target))
      }

      const updateActive = function(ndx) {
        active = ndx
        $('[data-scroll-nav]').removeClass('is-active')
        $('[data-scroll-nav=' + ndx + ']').addClass('is-active')
      }

      const watchActive = function() {
        const winTop = $(window).scrollTop()

        const visible = $('[data-scroll-index]').filter(function(ndx, div) {
            return winTop >= $(div).offset().top &&
            winTop < $(div).offset().top + $(div).outerHeight()
        })
        const newActive = visible.first().attr('data-scroll-index')
        updateActive(newActive)
      }

      $(window).on('scroll', watchActive)

      $('body').on('click','[data-scroll-nav], [data-scroll-goto]', function(e){
        e.preventDefault()
        $('.navbar-burger').removeClass('is-active')
        $('.navbar-menu').removeClass('is-active')
        doScroll(e)
      })
    },
    getCurrentYear() {
      $('.year').html(new Date().getFullYear())
    },
  }
})( jQuery )

$(() => {
  pvf.init()
})
